@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.button-pop-out {
  transition: transform 0.2s ease;
}

.button-pop-out:hover {
  transform: scale(1.1);
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slideInLeft {  
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slideInRight {  
  animation: 1s ease-out 0s 1 slideInFromRight;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slideInBottom {  
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1.3s ease-out 0s 1;
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.instagram {
  background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.contact-button {
  display: inline-block;
  padding: 8px 20px;
  margin: 5px;
  background: none;
  border: 2px solid #000;
  color: #000;
  text-decoration: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
  width: 300px; /* Adjust the width as needed */
}

.contact-button:hover {
  background-color: #fff;
}

@media (max-width: 520px) {
  .navbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75% !important;
  }
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    align-items: center;
  }

  .main-container > .contact-info-container,
  .main-container > .carousel-container {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .contact {
    /* font-size: 30px !important; */
    margin-top: 100px;
  }
}

@media (max-width: 1050px) {
  .main-container {
    align-items: center;
  }

  .main-container > .contact-info-container,
  .main-container > .carousel-container {
    max-width: 700px;
    text-align: center;
    margin-bottom: 20px;
  }

  .contact {
    font-size: 30px;    
  }
  .about {
    height: 700px;
  }
}

@media (min-width: 1070px) {
  .contact {
    font-size: 35px;
    margin-top: 100px;
  }
  .navbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
  }
}